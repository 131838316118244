@import "./desktop/desktop.scss";
@import "./windows/main.scss";
@import "./funny/main.scss";
@import "./projects/main.scss";

.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.mobile-app {
  .back {
    color: gray;
    font-size:xx-large;  
    padding : 0.2rem 0 0.5rem 0.4rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
  }
}
  