@import "../global.scss";
  .blog-posts {
      display: grid;
      grid-template-rows: auto 1fr;

      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;

    & > .head,
    & > .body > * {
      display: grid;
      grid-template-columns: 2rem 2fr 1fr 1fr;
    }

    .body {
        background-color: white;
        border: 0.1rem #41423d solid;
    }

    & > .head {
      border-bottom: 0.1rem #41423d solid;
      text-align: left;
      & > div {
        background-color: #d3d0c9;
        padding: 0.2rem 0.5rem;
        @include unpressed(0.1rem);
      }
    }

    & > .body {
      .blog-post-entry {
        .cell.image {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 1rem;
            height: 1rem;
          }
        }

        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
      }
    }
}

.mobile-blog-posts {

  .mobile-blog-post-entry {
    display: grid;
    grid-template-columns: 4rem 1fr;
    grid-template-rows: 5rem;
    padding-right: 1rem;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .description {
      padding-top: 1rem;
      border-bottom: 0.05rem solid gray;
      .title {
        font-weight: bold;
      }

      .sub {
        color: gray;
        font-weight: 100;
      }
    }
  }

}

.mobile-blog-post {
  padding: 1rem;
}