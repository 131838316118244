.shutdown-screen {
    background-color: black;
    width: 100vw;
    height: 100vh;
    color: #CE855C;
    display: flex;
    justify-content: center;
    align-items: center;
    font-stretch: condensed;
    text-align: center;
    letter-spacing: -0.1rem;

    font-size: 8rem;
}