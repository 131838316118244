@import "../global.scss";

$iconSize: 1.2rem;

.gl-window {
  @include unpressed(1px);
  padding: 0.2rem;
  display: flex;
  flex-direction: column;

  background-color: #d6d3ce;
  height: 100%;

  &.maximalized
  {
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
  }
  
  .title-bar {
    padding: 0.2rem;
    display: grid;
    grid-template-columns: auto 1fr auto;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#08246b+0,a5cbf7+100 */
    background: #848284; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #848284 0%,
      #c6c3c6 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #848284 0%,
      #c6c3c6 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #848284 0%,
      #c6c3c6 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#848284', endColorstr='#C6C3C6',GradientType=1 ); /* IE6-9 */

    &.active {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#08246b+0,a5cbf7+100 */
      background: #08246b; /* Old browsers */
      background: -moz-linear-gradient(
        left,
        #08246b 0%,
        #a5cbf7 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        #08246b 0%,
        #a5cbf7 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        #08246b 0%,
        #a5cbf7 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#08246b', endColorstr='#a5cbf7',GradientType=1 ); /* IE6-9 */
    }

    .icon-container {
      width: 1rem;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 0.1rem;
      .title-icon {
        width: 1rem;
        height: 1rem;
      }
    }

    .title {
      color: white;
    }

    .buttons {
      user-select: none;
      display: flex;
      .title-button {
        cursor: pointer;
        width: $iconSize;
        height: $iconSize;
        background-color: #d6d3ce;
        margin-left: 0.25rem;
        @include unpressed(1px);
        .maximalize-button {
          border: 1px solid black;
          border-top: 3px solid black;
          height: 100%;
        }

        svg {
          stroke: black;
          width: $iconSize;
          display: block;
          stroke-width: 0.5rem;
        }

        .maximalize {
          stroke-width: 0.25rem;

          .box {
            fill: transparent;
          }
        }

        &:active {
          @include pressed(1px);
        }
      }
    }
  }

  &>.content {
    flex-grow: 1;
    overflow: auto;
    padding: 0.2rem 0.4rem;
    position: relative;
    background-color: #d6d3ce;
  }


}
