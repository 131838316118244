@import "../global.scss";
@import "./mobile.scss";

.projects {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height:100%;
    .projects-list 
    {
        background-color: white;
        @include pressed(0.1rem);
        padding: 0.5rem;

        .project-category-short {
            .project-category-short-title {
            display: flex;
            align-items: center;

            img {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.5rem;
            }

            }

        .project-short {
            margin-left: 5%;
            display: flex;
            align-items: center;

            img {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.5rem;
            }

            &:hover {
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

    }

    .project-renderer {
        padding : 0.5rem;

        .photos {
            display: grid;

            grid-template-columns: 1fr 1fr;
            grid-row-gap: 1rem;
            grid-column-gap: 1rem;

            div
            {
                width: 100%;
                img {
                    width: 100%;
                    height: 100%
                }
            }
        }

    }
}