@mixin unpressed($size) {
    border-top: $size solid white;
    border-left: $size solid white;
    border-right: $size solid #424142;
    border-bottom: $size solid #424142
}

@mixin pressed($size) {
    border-right: $size solid white;
    border-bottom: $size solid white;
    border-top: $size solid #424142;
    border-left: $size solid #424142
}

@mixin horizontal-padding($padding) {
    padding-left: $padding;
    padding-right: $padding;
}